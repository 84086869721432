import React, { useState, useEffect, setGlobal, useGlobal } from "reactn";
import API from "../../api";
import Container from "../../components/Container";
import Table from "../../components/Table";
import Status from "../../components/Status";
import Form from "../../components/Form";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { withRouter } from "react-router-dom";
import "../../functions";
import { getUserId } from "../../functions";

const Profile = ({
	match: {
		params: { id }
	},
	history
}) => {
	useEffect(function() {
		(function() {
			setGlobal({ title: "Profile" });
		})();
	}, []);

	const [, setIsDirty] = useGlobal("isDirty");
	const [editMode, setEditMode] = useState(false);
	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);
	const [modal, setModal] = useState(false);

	useEffect(function() {
		(async function() {
			const { success, error, info } = await API.users.findOne(
				getUserId()
			);

			if (success) setUser(info);
			else setError(error);
		})();
	}, []);

	const handleFieldChange = (key, value) => {
		const fields = { ...user };
		fields[key] = value;
		setUser(fields);
		setIsDirty(true);
	};

	const handlePasswordReset = async () => {
		const { success, error } = await API.action.requestPasswordReset();

		if (success) setModal(true);
		else setError(error);
	};

	const handleSave = async () => {
		const { success, error } = await API.users.updateOne(getUserId(), user);

		if (success) {
			setIsDirty(false);
			history.push("/");
		} else setError(error);
	};

	return (
		<Container>
			<Status error>{error}</Status>
			{user ? (
				<>
					<p
						className="text-primary"
						style={{ textAlign: "center" }}
						onClick={() => setEditMode(!editMode)}
					>
						<a>Switch to {editMode ? "review" : "edit"} mode</a>
					</p>
					{editMode ? (
						<Form onSubmit={() => handleSave()}>
							{Object.keys(user).map((key, index) => (
								<Form.Group key={index}>
									<Form.Label>{key.capitalize()}</Form.Label>
									<Form.Input
										key={index}
										value={user[key].toString() || ""}
										onChange={({ value }) =>
											handleFieldChange(key, value)
										}
									/>
								</Form.Group>
							))}
							<Button type="submit" variant="primary">
								Save
							</Button>
						</Form>
					) : (
						<Table>
							<tbody>
								{Object.keys(user).map((key, index) => (
									<tr key={index}>
										<th>{key.capitalize()}</th>
										<td>{user[key]}</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
					<p
						className="text-primary"
						style={{ textAlign: "center" }}
						onClick={() => handlePasswordReset()}
					>
						<a>Reset your password</a>
					</p>
					<Modal show={modal} onClose={() => setModal(false)}>
						<Modal.Body>
							We have recieved your request. Please check your
							email.
						</Modal.Body>
						<Modal.ButtonGroup>
							<Button
								variant="primary"
								onClick={() => setModal(false)}
							>
								Dismiss
							</Button>
						</Modal.ButtonGroup>
					</Modal>
				</>
			) : (
				<Status.Switch data={user} />
			)}
		</Container>
	);
};

export default withRouter(Profile);
