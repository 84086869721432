import React from "react";
import Masonry from "react-masonry-component";
import "../scss/columns.scss";

const Columns = ({children}) => (
    <Masonry 
        className="columns"
        options={{
            gutter: 10,
            columnWidth: '.panel',
            transitionDuration: 0
        }}>
        {children}
    </Masonry>);

export default Columns;