import React, {useState, useEffect, setGlobal} from "reactn";
import {withRouter} from "react-router-dom";
import Form from "../../components/Form";
import Container from "../../components/Container";
import Button from "../../components/Button";
import CodeEditor from "../../components/CodeEditor";
import Status from "../../components/Status";
import API from "../../api";

const defaultText = `{
    "title": "",
    "sections": [{
        "title": "",
        "fields": [{

        }]
    }]
}`;

const CreateForm = ({history}) => {
    useEffect(function() {
        (function() {
            setGlobal({title: "Create Form"})
        })();
    }, []);

    const [title, setTitle] = useState("");
    // Add this functionality later
    const [type, setType] = useState("form");
    const [form, setForm] = useState(defaultText);
    const [error, setError] = useState(null);

    const handleSubmit = async () => {
        const object = new Function(`return (${form})`)();

        const {success, error} = await API.forms.insertOne({title, type, form: object});

        if(success) history.push("/settings/forms");
        else setError(error);
    }

    return (<Container>
        <Status error>{error}</Status>
        <Form onSubmit={() => handleSubmit()}>
            <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Input type="text" value={title} onChange={({value}) => setTitle(value)}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Select value={title} onChange={({value}) => setTitle(value)} options={[
                    {text: "Form", value: "form"},
                    {text: "Sub Form", value: "subform"}   
                ]}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Form</Form.Label>
                <CodeEditor defaultValue={defaultText} onChange={value => setForm(value)}/>
            </Form.Group>
            <Form.Group>
                <Button variant="primary" type="submit">Create</Button>
            </Form.Group>
        </Form>
    </Container>);
}

export default withRouter(CreateForm);
