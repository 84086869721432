import { API_ROOT, switchTableData } from "../../functions";
import React, { setGlobal, useEffect, useGlobal, useState } from "reactn";
import { Route, Switch, withRouter } from "react-router-dom";
import {
	faEdit,
	faFilePdf,
	faUserEdit,
} from "@fortawesome/free-solid-svg-icons";

import API from "../../api";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import Container from "../../components/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import PrintRecord from "./PrintRecord";
// import { Prompt } from "react-router";
import Section from "../../components/Section";
import Status from "../../components/Status";
import Table from "../../components/Table";

const Main = ({
	match: {
		url,
		params: { id: record_id },
	},
	contract_id,
	history,
	canEdit,
	canArchive,
}) => {
	const [isDirty, setIsDirty] = useGlobal("isDirty");
	const [editMode, setEditMode] = useState(false);
	const [record, setRecord] = useState(null);
	const [formattedRecord, setFormattedRecord] = useState(null);
	const [completed, setCompleted] = useState(false);
	const [error, setError] = useState(null);

	useEffect(
		function () {
			(function () {
				if (record && record.title) setGlobal({ title: record.title });
			})();
		},
		[record]
	);

	const handleLeavePage = () => {
		return true;
	};

	useEffect(function () {
		(async function () {
			const { success, error, formattedRecord, record, isOwner } =
				await API.records.findOne(contract_id, record_id);

			if (success) {
				setCompleted(record.completed);
				setEditMode(isOwner);
				setRecord(record);
				setFormattedRecord(formattedRecord);

				console.log({ record, formattedRecord });
			} else setError(error);
		})();
	}, []);

	useEffect(
		function () {
			(async function () {
				const { success, error } = await API.records.updateOne(
					contract_id,
					record_id,
					{
						completed,
					}
				);

				if (!success) setError(error);
			})();
		},
		[completed]
	);

	const handleFieldChange = async (inputState) => {
		const temp = { ...record };

		const index = temp.fields.findIndex(
			(field) => field.uid === inputState.uid
		);

		if (index !== -1) {
			temp.fields[index] = inputState;
		} else {
			temp.fields.push(inputState);
		}

		setRecord(temp);
		setIsDirty(true);

		if (!window.onbeforeunload) window.onbeforeunload = handleLeavePage;
	};

	const handleSave = async () => {
		const { success, error } = await API.records.updateOne(
			contract_id,
			record_id,
			record
		);

		if (success) {
			setIsDirty(false);
			if (!window.onbeforeunload) window.onbeforeunload = null;

			history.push("/contracts");
		} else setError(error);
	};

	const handleArchive = async () => {
		const { success, error } = await API.records.archiveOne(
			contract_id,
			record_id
		);

		if (success) history.push("/contracts");
		else setError(error);
	};

	const inline = {
		display: "inline-block",
		verticalAlign: "middle",
	};

	return (
		<React.Fragment>
			{/* <Prompt
				when={isDirty}
				message="You have unsaved changes, are you sure you want to leave?"
			/> */}
			<Container>
				<img
					style={{
						margin: "10px 0",
					}}
					width="125px"
					src="http://www.resinbonded.co.uk/wp-content/uploads/2017/09/logo.png"
				/>
				<Status error>{error}</Status>
				{formattedRecord ? (
					<>
						<Section style={{ clear: "both", overflow: "auto" }}>
							<Form.Group style={{ float: "left" }}>
								<Form.Checkbox
									style={inline}
									checked={completed}
									onChange={({ checked }) =>
										setCompleted(checked)
									}
								/>
								<Form.Label style={inline}>
									Mark as completed
								</Form.Label>
							</Form.Group>
							<Form.Group style={{ float: "right" }}>
								<h3 style={{ margin: "0" }}>
									{canEdit && !completed && (
										<a
											title={`Switch to ${
												editMode ? "read" : "edit"
											} mode`}
											onClick={() =>
												setEditMode(!editMode)
											}
											className="text-primary"
										>
											<FontAwesomeIcon
												icon={
													editMode
														? faUserEdit
														: faEdit
												}
											/>
										</a>
									)}

									<a
										style={{ marginLeft: "10px" }}
										title={`Download / Print record PDF`}
										href={
											API_ROOT +
											`/contract/${contract_id}/record/${record_id}/pdf`
										}
										target="_blank"
										className="text-primary"
									>
										<FontAwesomeIcon icon={faFilePdf} />
									</a>
									{/* )} */}
								</h3>
							</Form.Group>
						</Section>
						{editMode ? (
							<Form>
								{formattedRecord.map(({ title, fields }, i) => (
									<Section title={title} key={`section-${i}`}>
										{fields.map((formField, j) => {
											const recordField =
												record.fields.find(
													({ uid }) =>
														formField.uid === uid
												);

											let inherit =
												recordField || formField;

											return (
												<Form.Switch
													{...inherit}
													onChange={(state) =>
														handleFieldChange(state)
													}
													key={`field-${j}`}
												/>
											);
										})}
									</Section>
								))}
								<Button
									type="button"
									variant="primary"
									onClick={() => handleSave()}
								>
									Save
								</Button>
							</Form>
						) : (
							formattedRecord.map(({ title, fields }, i) => (
								<Section
									title={title}
									key={`record-table-${i}`}
								>
									<Table>
										<tbody>
											{fields.length ? (
												fields
													.sort(
														(a, b) => a.uid - b.uid
													)
													.map((field, index) => {
														return (
															<tr key={index}>
																<th>
																	{
																		field.label
																	}
																</th>
																<td>
																	{switchTableData(
																		field
																	)}
																</td>
															</tr>
														);
													})
											) : (
												<tr>
													<td>
														<Status empty />
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</Section>
							))
						)}
					</>
				) : (
					<Status.Switch data={record} />
				)}
				{canArchive && (
					<Confirm onConfirm={() => handleArchive()}>
						Archive record
					</Confirm>
				)}
			</Container>
			<Footer />
		</React.Fragment>
	);
};

const Record = (props) => (
	<Switch>
		<Route
			exact
			path={props.match.url}
			render={() => <Main {...props} />}
		/>
		<Route
			path={`${props.match.url}/print`}
			render={() => <PrintRecord {...props} />}
		/>
	</Switch>
);

export default withRouter(Record);
