import React from "reactn";

import "../scss/panel.scss";
import "../scss/main.scss";

const Panel = props => {
    const inherit = {...props};
    delete inherit.completed;

    return (
        <div {...inherit} className={`panel`}>
            {props.notif && <span className="panel-notif" variant="danger">{props.notif}</span>}
            {props.children}
        </div>
    )
};

Panel.Image = props => <img alt="" {...props} className="panel-image"/>;
Panel.Header = props => <div {...props} className="panel-header">{props.children}</div>;
Panel.Title = props => <h1 {...props} className="panel-title">{props.children}</h1>;
Panel.Body = props => <div {...props} className="panel-body">{props.children}</div>;
Panel.Footer = props => <div {...props} className="panel-footer">{props.children}</div>;
Panel.Label = props => <div {...props} className="panel-label">{props.children}</div>;

export default Panel;