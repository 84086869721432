import React, {useGlobal} from "reactn";
import {Route, withRouter} from "react-router-dom";
import Index from './pages/Index';
import NavBar from './components/NavBar';
import Confirm from "./components/Confirm";

const App = ({history}) => {
	const [title] = useGlobal("title");
	const [, setIsDirty] = useGlobal("isDirty");
	const [pendingDest, setPendingDest] = useGlobal("pendingDest");

	return (<div className="App">
		{/* Wait for global state update */}
		<NavBar title={title}/>
		<main>
			<Route render={props => <Index {...props}/>}/>
		</main>

		{/*  */}
		<Confirm.Leave 
			show={pendingDest !== null}
			onConfirm={() => {
				history.push(pendingDest);
				setPendingDest(null);
				setIsDirty(false);
			}}
			onCancel={() => setPendingDest(null)}/>
	</div>);
}

export default withRouter(App);
