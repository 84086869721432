import React, {useEffect, useState, setGlobal} from "reactn";
import Status from "../../components/Status";
import API from "../../api";
import Form from "../../components/Form";

import prettifyJSON from "prettify-json";
import Container from "../../components/Container";
import Button from "../../components/Button";

import {withRouter} from 'react-router-dom';
import CodeEditor from "../../components/CodeEditor";
import Confirm from "../../components/Confirm";

const FormEditor = ({match: {params: {id}}, history}) => {
    useEffect(function() {
        (function() {
            setGlobal({title: "Edit Form"})
        })();
    }, []);

    const [form, setForm] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function() {
        (async function() {
            const {success, error, form} = await API.forms.findOne(id);

            if(success) setForm(prettifyJSON(form).replace(/ {2}/g, "\t"));
            else setError(error);
        })();
    }, []);

    const handleSubmit = async () => {
        const object = new Function(`return (${form})`)();

        const {success, error} = await API.forms.updateOne(id, object);

        if(success) history.push("/settings/forms");
        else setError(error);
    }

    const handleDelete = async () => {
        const {success, error} = await API.forms.deleteOne(id);

        if(success) history.push("/settings/forms");
        else setError(error);
    }

    return (<Container>
        <Status error>{error}</Status>
        {form ? (<Form onSubmit={() => handleSubmit()}>
            <Form.Group>
                <CodeEditor defaultValue={form} onChange={value => setForm(value)}/>
            </Form.Group>
            <Form.Group>
                <Button variant="primary" type="submit">Save</Button>
            </Form.Group>
            <Confirm onConfirm={() => handleDelete()}>Delete form</Confirm>
        </Form>) : <Status.Switch data={form}/>}
    </Container>);
}

export default withRouter(FormEditor);