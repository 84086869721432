import React, {useState} from "react";

import "../../scss/form.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal";
import API from "../../api";
import Status from "../Status";
import {SubForm, SubFormTable} from "../Form";

const OpenList = props => {
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);
    const [form, setForm] = useState(null);
    const [state, setState] = useState(props);

    const handleOpen = async () => {
        const {success, error, forms} = await API.forms.find({title: props.form, raw: true});

        if(success) setForm(forms[0]);
        else setError(error);

        setShow(true);
    }

    const addItem = item => {
        const {items} = state;
        
        items.push(item);

        setState({items});
        setShow(false);

        props.onChange(state);
    }

    const removeItem = index => {
        const {items} = state;

        items.splice(index, 1);

        setState({items});

        props.onChange(state);
    }

    const inherit = {...props};
    delete inherit.onChange;

    return (<div actionkey={props.actionkey}>
        {state.items && (<>
            <div style={{position: "relative"}} onClick={() => handleOpen()}>
                <div {...inherit} className="form-open-list" variant="white">Add to List</div>
                <FontAwesomeIcon icon={faPlus}/>
            </div>
            {(state.items.length > 0) ? 
            <SubFormTable 
                items={state.items} 
                onRemove={tr => removeItem(tr)}/> :
            <Status.Switch data={state.items}/>}
            <Modal show={show} onClose={() => setShow(false)}>
                {form ? 
                <SubForm form={form} onSubmit={item => addItem(item)}/> :
                <Status loading/>}
            </Modal>
        </>)}
        <Status error>{error}</Status>
    </div>);
}

export default OpenList;