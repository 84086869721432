import ImagePreview from "./components/ImagePreview";
import React from "react";
import { SubFormTable } from "./components/Form";
import jwt_decode from "jwt-decode";

export const getAuthorizedView = (auth, views) => {
	const view = views[auth] || views["none"];

	return view;
};

export const lastChanged = (ms) => {
	let now = Date.now(),
		milliseconds = now - ms,
		seconds = milliseconds / 1000,
		minutes = seconds / 60,
		hours = minutes / 60,
		days = hours / 24,
		weeks = days / 7,
		months = days / 30,
		years = days / 365,
		divs = {
			milliseconds,
			seconds,
			minutes,
			hours,
			days,
			weeks,
			months,
			years,
		},
		vals = Object.values(divs),
		keys = Object.keys(divs),
		delta = Object.keys(divs).length;

	while (delta--) {
		if (Math.floor(vals[delta]) > 0) break;
	}

	let amount = Math.floor(vals[delta]),
		unit = amount === 1 ? keys[delta].slice(0, -1) : keys[delta]; // 1 day, 2 days

	// e.g. 3 seconds ago
	return `${amount} ${unit}`;
};

export const API_ROOT =
	window.location.hostname === "localhost" ||
	window.location.hostname === "127.0.0.1" ||
	window.location.hostname === ""
		? "http://localhost:5000/api/v1"
		: "https://api.resinbonded.training/api/v1";

export const request = async (url, props = {}) => {
	console.info("URL", url);

	let req, json, text;
	try {
		// Stringify body
		props.body = JSON.stringify(props.body);

		// Request headers
		props.headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer: ${localStorage.getItem("token")}`,
		};

		req = await fetch(API_ROOT + url, props);
		const { status } = req;

		// Get response JSON
		json = await req.clone().json();
		// Get response text
		text = await req.clone().text();

		if (req.status >= 400) {
			if (req.status === 401 && window.location.pathname.length > 1) {
				// redirect
				window.location.href = "/";
			} else {
				throw { status, error: (json && json.error) || text };
			}
		}
	} catch (ex) {
		console.error(ex);
	}

	return json || text;
};

// Object to query string
export const toQuery = (params) => {
	return typeof params === "object"
		? "?" + new URLSearchParams(params).toString()
		: params;
};

export const switchTableData = ({ type, checked, value, items }) => {
	switch (type) {
		case "image":
			return <ImagePreview src={value} />;
		case "list":
			return <SubFormTable items={items} />;
		case "checkbox":
			return <p>{JSON.parse(checked) === true ? "Yes" : "No"}</p>;
		case "date":
			return <p>{value && new Date(value).toLocaleDateString()}</p>;
		default:
			return <p>{value}</p>;
	}
};

export const getUserId = () => jwt_decode(localStorage.getItem("token"))._id;
export const getUserAuth = () => jwt_decode(localStorage.getItem("token")).auth;

String.prototype.capitalize = function () {
	return this[0].toUpperCase() + this.substring(1, this.length);
};
