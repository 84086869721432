import React, {useState, useEffect, useRef} from "react";

import "../scss/form.scss";
import "../scss/main.scss";

import ImageSelect from "./fields/ImageSelect"
import FileSelect from "./fields/FileSelect";
import Checkbox from "./fields/Checkbox";
import OpenList from "./fields/OpenList";
import Select from "./fields/Select";
import Input from "./fields/Input";
import TextArea from "./fields/TextArea";
import Lookup from "./fields/Lookup";
import Button from "./Button";
import Section from "./Section";
import Status from "./Status";
import Table from "./Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {switchTableData} from "../functions";

import $ from "jquery";

const Form = props => {
    const form = useRef(null);

    useEffect(() => {
        // On Change handlers on children
        // Use the ref (?)

        const checkbox = $(form.current).find("input[type='checkbox']");
        $(checkbox).on("change", function() {
            const checked = $(this).is(":checked");
            const target = $(`[actionkey="${$(this).attr("toggle")}"]`).parent();

            if(checked) target.removeClass("hide");
            else target.addClass("hide");
        });
    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        if(event.target.checkValidity()) {
            props.onSubmit();
        }
    }

    const inherit = {...props,};
    delete inherit.onSubmit;

    return <form ref={form} {...inherit} onSubmit={e => handleSubmit(e)}>{props.children}</form>
}

export const SubForm = ({form, onSubmit}) => {
    const formFields = form.sections.map(section =>
        section.fields.map((field, index) => ({
            ...field//, id: index
        }))).flat();

    const [state, setState] = useState(formFields);
    const [error, setError] = useState(null);

    const handleFieldChange = inputState => {
        const recordFields = state;
        const index = recordFields.findIndex(field => field.uid === inputState.uid);

        recordFields[index] = inputState;

        setState(recordFields);
    }

    const handleSubmit = () => {
        // Fake submit
        let cancel = false;

        state.forEach(({required, value}) => {
            if(required && (!value || !value.length)) {
                cancel = true;
                return;
            }
        })

        if(!cancel) onSubmit(state);
        else setError("All fields must be filled in");
    }

    return (<Section title={form.title}>
        <Status error>{error}</Status>
        {formFields.map((field, index) => <Form.Switch 
            key={index} 
            onChange={fieldState => handleFieldChange(fieldState)} 
        {...field}/>)}
        <Form.Group>
            <Button 
                type="button" 
                variant="primary" 
                onClick={() => handleSubmit()}>
                Add to List</Button>
        </Form.Group>
    </Section>);
}

export const SubFormTable = ({items, onRemove = null}) => (items && items.length) ? (
    <Table>
        <tbody>
            <tr>
                {items[0].map(({label}, th) =>  
                    <th key={`th-${th}`}>{label}</th>
                )}
                {onRemove && <th></th>}
            </tr>
            {items.map((item, tr) =>  
                <tr key={`tr-${items.indexOf(item)}-${tr}`}>
                    {item.map((field, td) =>
                        <td key={`td-${td}`}>{switchTableData(field)}</td>
                    )}
                    {onRemove && <td
                        style={{textAlign: "center"}}
                        className="text-danger">
                        <FontAwesomeIcon 
                            style={{cursor: "pointer"}}
                            icon={faTrash} 
                            onClick={() => onRemove(tr)}/>
                    </td>}
                </tr>
            )}
        </tbody>
    </Table>) : <Status empty/>;

Form.Section = props => <section className="form-section" {...props}>{props.children}</section>;
Form.Select = Select;
Form.OpenList = OpenList;
Form.FileSelect = FileSelect;
Form.ImageSelect = ImageSelect;
Form.Checkbox = Checkbox;
Form.TextArea = TextArea;
Form.Input = Input;
Form.Lookup = Lookup;
Form.Label = props => <label {...props}>{props.children}</label>;
Form.Group = ({style, className = "", children}) => <section style={style} className={`form-group ${className}`}>{children}</section>;
Form.Switch = props => {
    // Returns element from field data
    const inherit = {...props};
    delete inherit.required;

    if(props.required) inherit.required = "required";

    const field = ((props, inherit) => {
        switch(props.type) {
            case "select":   	return <Form.Select {...inherit}/>;
            case "list":        return <Form.OpenList {...inherit}/>;
            case "file":        return <Form.FileSelect {...inherit}/>;
            case "image":       return <Form.ImageSelect {...inherit}/>;
            case "checkbox":    return <Form.Checkbox {...inherit}/>;
            case "lookup":      return <Form.Lookup {...inherit}/>;
            case "textarea":    return <Form.TextArea {...inherit}/>;
            default:            return <Form.Input {...inherit}/>;
        } 
    })(props, inherit);

    return (<Form.Group className={(typeof props.actionkey !== "undefined") ? "hide" : ""}>
        <Form.Label>{props.label}</Form.Label>
        <p className="form-field-desc">{props.description}</p>
        {field}
    </Form.Group>);
}

export default Form;
