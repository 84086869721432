import React, {useState} from "react";
import Form from "../Form";

const Lookup = props => {
    const [state, setState] = useState({value: "", inputValue: ""});
    const id = `list-${Math.ceil(Math.random() * 100000)}`;

    const handleChange = event => {
        const elem = document.querySelector(`#${id} option[value="${event.value}"]`);
        const value = (elem && elem.dataset) ? elem.dataset.value : null;
        const inputValue = event.value;

        setState({
            value, inputValue
        });

        props.onChange({value});
    }

    const inherit = {...props};
    delete inherit.type;
    delete inherit.onChange;

    return (
        <div className="form-lookup">
            <Form.Input {...inherit} type="text" value={state.inputValue} onChange={e => handleChange(e)} list={id}/>
            <datalist id={id}>
                {props.items.map((item, index) => 
                    <option key={`list-${id}-${index}`} data-value={item.value} value={item.text}/>
                )}
            </datalist>
        </div>
    );
};

export default Lookup;