import React, { useState } from "react";

import Button from "./Button";
import Modal from "./Modal";

const Confirm = ({ children, onConfirm }) => {
	const [modal, setModal] = useState(false);

	return (
		<>
			<a>
				<p
					onClick={(e) => {
						e.stopPropagation();
						setModal(true);
					}}
					className="text-danger"
					style={{ textAlign: "center", cursor: "pointer" }}
				>
					{children}
				</p>
			</a>

			{
				<Modal show={modal} onClose={() => setModal(false)}>
					<Modal.Body>
						This action may not be reversible.
						<br />
						Click <i>OK</i> to confirm.
					</Modal.Body>
					<Modal.ButtonGroup>
						<Button
							variant="secondary"
							onClick={() => setModal(false)}
							style={{ marginRight: "10px" }}
						>
							Cancel
						</Button>
						<Button variant="danger" onClick={() => onConfirm()}>
							OK
						</Button>
					</Modal.ButtonGroup>
				</Modal>
			}
		</>
	);
};

Confirm.Leave = ({ show, onConfirm, onCancel }) => (
	<Modal show={show} onClose={onCancel}>
		<Modal.Body>
			Are you sure you want to leave this page?
			<br />
			<b>Any unsaved changes will be lost.</b>
		</Modal.Body>
		<Modal.ButtonGroup>
			<Button
				variant="danger"
				onClick={onConfirm}
				style={{ marginRight: "10px" }}
			>
				Yes, Leave
			</Button>
			<Button variant="secondary" onClick={onCancel}>
				Cancel
			</Button>
		</Modal.ButtonGroup>
	</Modal>
);

export default Confirm;
