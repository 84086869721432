import React, { useEffect, setGlobal } from "reactn";

import { Link } from "react-router-dom";

import Container from "../../components/Container";
import Panel from "../../components/Panel";
import Columns from "../../components/Columns";

const Dashboard = () => {
	useEffect(function () {
		(function () {
			setGlobal({ title: "Dashboard" });
		})();
	}, []);

	return (
		<Container>
			<Columns>
				<Panel variant="white">
					<Link to="/contracts">
						<Panel.Title>Manage Contracts</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/manage-contracts.svg")}
							/>
						</Panel.Body>
					</Link>
				</Panel>
				<Panel variant="white">
					<Link to="/users">
						<Panel.Title>Manage Users</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/manage-users.svg")}
							/>
						</Panel.Body>
					</Link>
				</Panel>
				<Panel variant="white">
					<Link to="/data-sheets">
						<Panel.Title>Data Sheets</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/data-sheets.svg")}
							/>
						</Panel.Body>
					</Link>
				</Panel>
				<Panel variant="white">
					<Link to="/settings">
						<Panel.Title>Advanced Settings</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/advanced-settings.svg")}
							/>
						</Panel.Body>
					</Link>
				</Panel>
			</Columns>
		</Container>
	);
};

export default Dashboard;
