import React, {useState, useRef} from "reactn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Checkbox = props => {
    const [isChecked, setIsChecked] = useState(JSON.parse(props.checked) || false);

    const checkbox = useRef(null);

    const handleChange = ({target: {checked}}) => {
        const state = {...props};
        state.checked = checked;
        
        props.onChange(state);
        setIsChecked(checked);
    }

    let inherit = {...props};
    delete inherit.checked;
    delete inherit.onChange;

    return (
        <div style={{position: "relative", ...props.style}}>
            <input 
                checked={isChecked}
                ref={checkbox} 
                style={{opacity: 0}} 
                onChange={event => handleChange(event)} 
                {...inherit} 
                type="checkbox"/>
            <div className={`form-pseudo-checkbox ${isChecked ? "checked" : ""}`}>
                <div className="form-pseudo-check">
                    <FontAwesomeIcon icon={faCheck}/>
                </div>
            </div>
        </div>
    );
}

export default Checkbox;