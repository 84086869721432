import React from "reactn";

const Input = props => {
    const handleChange = ({target: {value}}) => {
        const state = {...props};
        state.value = value;
        
        props.onChange(state);
    }

    const inherit = {...props};
    delete inherit.onChange;

    return <input {...inherit} onChange={event => handleChange(event)} variant="white" autoComplete="imaginary" />
}

export default Input;