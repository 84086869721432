import React from "react";

const Footer = () => {
	return (
		<footer style={{ margin: "15px 0 0" }}>
			<p className="text-muted" align="center">
				Resin Bonded Ltd. <br />
				Unit F1 <br />
				Birdineye Farm <br />
				Framfield Road <br />
				Uckfield TN22 5HA
			</p>
		</footer>
	);
};

export default Footer;
