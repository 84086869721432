import "../../functions";

import React, { setGlobal, useEffect, useState } from "reactn";

import API from "../../api";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import Container from "../../components/Container";
import Form from "../../components/Form";
import Status from "../../components/Status";
import Table from "../../components/Table";
import { withRouter } from "react-router-dom";

const User = ({
	match: {
		params: { id },
	},
	history,
	canEdit,
	canDelete,
}) => {
	useEffect(function () {
		(function () {
			setGlobal({ title: "User" });
		})();
	}, []);

	const [editMode, setEditMode] = useState(false);
	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);

	useEffect(function () {
		(async function () {
			const { success, error, info } = await API.users.findOne(id);

			if (success) setUser(info);
			else setError(error);
		})();
	}, []);

	const setField = (key, value) => {
		const fields = { ...user };
		fields[key] = value;
		setUser(fields);
	};

	const handleSubmit = async () => {
		const { success, error } = await API.users.updateOne(id, user);

		if (success) history.push("/users");
		else setError(error);
	};

	const handleDelete = async () => {
		const { success, error } = await API.users.deleteOne(id);

		if (success) history.push("/users");
		else setError(error);
	};

	return (
		<Container>
			<Status error>{error}</Status>
			{user ? (
				<>
					{canEdit && (
						<p
							className="text-primary"
							style={{ textAlign: "center" }}
							onClick={() => setEditMode(!editMode)}
						>
							<a>Switch to {editMode ? "review" : "edit"} mode</a>
						</p>
					)}
					{editMode ? (
						<Form onSubmit={() => handleSubmit()}>
							{Object.keys(user).map((key, index) => (
								<Form.Group key={index}>
									<Form.Label>{key.capitalize()}</Form.Label>
									<Form.Input
										key={index}
										value={
											user[key] !== null
												? user[key].toString()
												: ""
										}
										onChange={({ value }) =>
											setField(key, value)
										}
									/>
								</Form.Group>
							))}
							<Button type="submit" variant="primary">
								Save
							</Button>
						</Form>
					) : (
						<Table>
							<tbody>
								{Object.keys(user).map((key, index) => (
									<tr key={index}>
										<th>{key.capitalize()}</th>
										<td>{user[key]}</td>
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</>
			) : (
				<Status.Switch data={user} />
			)}
			{canDelete && (
				<Confirm onConfirm={() => handleDelete()}>Delete user</Confirm>
			)}
		</Container>
	);
};

export default withRouter(User);
