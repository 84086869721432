import { request, toQuery } from "./functions";

import { setGlobal } from "reactn";

const API = {
	action: {
		validate: async () => {
			const response = await request(`/user/session`);
			setGlobal({ valid: response.success });

			return response;
		},
		validateToken: async (body) =>
			await request(`/user/validateToken`, { method: "POST", body }),
		login: async (body) =>
			await request(`/user/session`, { method: "POST", body }),
		logout: async () => {
			const response = await request(`/user/session`, {
				method: "DELETE",
			});

			if (response.success) {
				setGlobal({ valid: false });
				localStorage.clear();
			}

			return response;
		},
		requestPasswordReset: async () =>
			await request(`/user/password`, { method: "POST" }),
		resetPassword: async (body) =>
			await request(`/user/password`, { method: "PATCH", body }),
	},

	users: {
		find: async (params = {}) => await request(`/users${toQuery(params)}`),
		findOne: async (_id, params = {}) =>
			await request(`/user/${_id}${toQuery(params)}`),
		insertOne: async (body) =>
			await request(`/user`, { method: "PUT", body }),
		updateOne: async (_id, body) =>
			await request(`/user/${_id}`, { method: "PATCH", body }),
		archiveOne: async (_id) =>
			await request(`/contract/${_id}`, {
				method: "PATCH",
				body: { archived: true },
			}),
		deleteOne: async (_id) =>
			await request(`/user/${_id}`, { method: "DELETE" }),
		forgotPassword: async (email) =>
			await request(`/user/forgotPassword`, {
				method: "POST",
				body: { email },
			}),
	},
	contracts: {
		find: async (params = {}) =>
			await request(`/contracts${toQuery(params)}`),
		findOne: async (_id, params = {}) =>
			await request(`/contract/${_id}${toQuery(params)}`),
		insertOne: async (body) =>
			await request(`/contract`, { method: "PUT", body }),
		updateOne: async (_id, body) =>
			await request(`/contract/${_id}`, { method: "PATCH", body }),
		archiveOne: async (_id) =>
			await request(`/contract/${_id}`, {
				method: "PATCH",
				body: { archived: true },
			}),
		deleteOne: async (_id) =>
			await request(`/contract/${_id}`, { method: "DELETE" }),
	},
	records: {
		find: async (contract_id, params = {}) =>
			await request(`/contract/${contract_id}/records${toQuery(params)}`),
		findOne: async (contract_id, _id, params = {}) =>
			await request(
				`/contract/${contract_id}/record/${_id}${toQuery(params)}`
			),
		insertOne: async (contract_id, body) =>
			await request(`/contract/${contract_id}/record`, {
				method: "PUT",
				body,
			}),
		updateOne: async (contract_id, _id, body) =>
			await request(`/contract/${contract_id}/record/${_id}`, {
				method: "PATCH",
				body,
			}),
		archiveOne: async (contract_id, _id) =>
			await request(`/contract/${contract_id}/record/${_id}`, {
				method: "PATCH",
				body: { archived: true },
			}),
		deleteOne: async (contract_id, _id) =>
			await request(`/contract/${contract_id}/record/${_id}`, {
				method: "DELETE",
			}),
		// toPDF: async (contract_id, _id) =>
		// 	await request(`/contract/${contract_id}/record/${_id}/pdf`),
		printOne: async (contract_id, _id) =>
			await request(`/contract/${contract_id}/record/${_id}/print`, {
				method: "POST",
			}),
		fields: {
			updateOne: async (contract_id, record_id, uid, body) =>
				await request(
					`/contract/${contract_id}/record/${record_id}/field/${uid}`,
					{
						method: "PATCH",
						body,
					}
				),
		},
	},
	forms: {
		find: async (params = {}) => await request(`/forms${toQuery(params)}`),
		findOne: async (_id, params = {}) =>
			await request(`/form/${_id}${toQuery(params)}`),
		insertOne: async (body) =>
			await request(`/form`, { method: "PUT", body }),
		updateOne: async (_id, body) =>
			await request(`/form/${_id}`, { method: "PATCH", body }),
		deleteOne: async (_id) =>
			await request(`/form/${_id}`, { method: "DELETE" }),
	},
};

export default API;
