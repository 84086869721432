import React from "react";
import "../scss/text.scss";
import "../scss/loading.scss";

const Status = ({children, loading, error, empty}) => {
    if(children || loading || empty) {
        return (<p className={`status ${error ? "text-danger" : ""}`}>
            {empty && "No results"}
            {loading && (<>
                <img className="loading" alt="Loading" src={require("../assets/images/loading.gif")}/>
                Loading...
            </>)}
            {children}
        </p>)
    } else return "";
}

Status.Switch = ({data}) => data ? <Status empty/> : <Status loading/>;

export default Status;