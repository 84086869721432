import React, { useEffect, setGlobal } from "reactn";
import Panel from "../../components/Panel";
import Container from "../../components/Container";
import Columns from "../../components/Columns";

const DataSheets = () => {
	useEffect(function () {
		(function () {
			setGlobal({ title: "Data Sheets" });
		})();
	}, []);

	return (
		<Container>
			<Columns>
				<Panel variant="white">
					<a href={require("../../assets/files/data-sheet.pdf")}>
						<Panel.Title>Data Sheet</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/data-sheets.svg")}
							/>
						</Panel.Body>
					</a>
				</Panel>
				<Panel variant="white">
					<a
						href={require("../../assets/files/application-data-sheet.pdf")}
					>
						<Panel.Title>Application Data Sheet</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/data-sheets.svg")}
							/>
						</Panel.Body>
					</a>
				</Panel>
			</Columns>
		</Container>
	);
};

export default DataSheets;
