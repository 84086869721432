import React, { setGlobal, useEffect, useState } from "reactn";

import API from "../../api";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Form from "../../components/Form";
import Status from "../../components/Status";

const CreateUser = ({ history }) => {
	useEffect(function () {
		(async function () {
			setGlobal({ title: "New User" });
		})();
	}, []);

	const [forename, setForename] = useState("");
	const [surname, setSurname] = useState("");
	const [email, setEmail] = useState("");
	const [company, setcompany] = useState("");
	const [auth, setAuth] = useState("client");
	const [error, setError] = useState(null);

	const handleSubmit = async () => {
		const { success, error } = await API.users.insertOne({
			forename,
			surname,
			email,
			company,
			auth,
		});

		if (success) history.push("/");
		else setError(error);
	};

	return (
		<Container>
			<Status error>{error}</Status>
			<Form onSubmit={() => handleSubmit()}>
				<Form.Group>
					<Form.Label>Forename</Form.Label>
					<Form.Input
						required
						type="text"
						placeholder="Enter forename"
						onChange={({ value }) => setForename(value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Surname</Form.Label>
					<Form.Input
						required
						type="text"
						placeholder="Enter surname"
						onChange={({ value }) => setSurname(value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Email</Form.Label>
					<Form.Input
						required
						type="email"
						placeholder="Enter email"
						onChange={({ value }) => setEmail(value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Company Name</Form.Label>
					<Form.Input
						required
						type="text"
						placeholder="Enter company name"
						onChange={({ value }) => setcompany(value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Access Level</Form.Label>
					<Form.Select
						options={[
							{ text: "Client", value: "client" },
							{ text: "Employee", value: "employee" },
							{ text: "Administrator", value: "admin" },
						]}
						onChange={({ value }) => setAuth(value)}
					/>
				</Form.Group>
				<Form.Group>
					<Button type="submit" variant="primary">
						Create user
					</Button>
					<p>
						<i>
							This will brief the client on how to set up their
							account on the system.
						</i>
					</p>
				</Form.Group>
			</Form>
		</Container>
	);
};

export default CreateUser;
