import React, {useState} from "reactn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const Select = props => {
    const [selected, setSelected] = useState(props.value);

    const handleChange = ({target: {options, selectedIndex}}) => {
        const state = {...props};
        state.value = options[selectedIndex].value;
        
        setSelected(state.value);
        props.onChange(state);
    }

    const inherit = {...props};
    delete inherit.value;
    delete inherit.onChange;

    return (
        <div style={{position: "relative"}}>
            <select {...inherit} 
                onChange={event => handleChange(event)} 
                value={selected}
                variant="white">{
                inherit.options.map((option, index) =>
                    <option key={`option-${index}`} value={option.value}>{option.text}</option>)
            }</select>
            <FontAwesomeIcon icon={faChevronDown}/>
        </div>
    );
}

export default Select;