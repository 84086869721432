import React, {useEffect, setGlobal, useState} from "reactn";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Status from "../../components/Status";
import API from "../../api";

const LogOut = () => {
    useEffect(function() {
        (function() {
            setGlobal({title: "Log out"});
        })();
    }, []);

    const [error, setError] = useState(null);

    const handleLogOut = async () => {
        const {success, error} = await API.action.logout();
        
        if(success) window.location.href = "/";
        else setError(error);
    }

    return (<Container>
        <p>Are you sure you want to log out?</p>
        <Button 
            variant="primary"
            onClick={handleLogOut}>Log out</Button>

        <Status error>{error}</Status>
    </Container>);
}

export default LogOut;