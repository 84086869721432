import React, {useState} from "react";
import Button from "./Button";
import Modal from "./Modal";

const ImagePreview = ({src}) => {
    const [modal, setModal] = useState(false);

    return (<>
        <img onClick={() => setModal(true)} alt="" style={{maxWidth: "100px", cursor: "pointer"}} src={src}/>
        <Modal show={modal} onClose={() => setModal(false)}>
            <Modal.Body>
                <img alt="" style={{width: "100%"}} src={src}/>
            </Modal.Body>
            <Modal.ButtonGroup>
                <Button variant="secondary" onClick={() => setModal(false)}>Close</Button>
            </Modal.ButtonGroup>
        </Modal>
    </>)
}

export default ImagePreview;