import Contract from "./view/Contract";
import Contracts from "./view/Contracts";
import CreateContract from "./view/CreateContract";
import DataSheets from "./view/DataSheets";
import LogOut from "./view/LogOut";
import PasswordReset from "./view/PasswordReset";
import Profile from "./view/Profile";
import Record from "./view/Record";
import User from "./view/User";
import Users from "./view/Users";

const View = {
	Contract,
	Contracts,
	CreateContract,
	DataSheets,
	LogOut,
	PasswordReset,
	Profile,
	Record,
	User,
	Users,
};

export default View;
