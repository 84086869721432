import React, { useState } from "react";
import API from "../api";
import jwt_decode from "jwt-decode";
import Container from "./Container";
import Form from "./Form";
import Button from "./Button";
import Modal from "./Modal";
import Status from "./Status";
import { Link } from "react-router-dom";

const ForgotPassword = ({ onError, onSubmit }) => {
	const [sent, setSent] = useState(false);
	const [email, setEmail] = useState("");

	const handleSubmit = async () => {
		const { success, response, error } = await API.users.forgotPassword(
			email
		);

		if (success) {
			setSent(true);
		} else onError(error);
	};

	return !sent ? (
		<Form onSubmit={handleSubmit}>
			<Form.Group>
				<Form.Label>Email</Form.Label>
				<Form.Input
					placeholder="Enter your email"
					type="email"
					onChange={({ value }) => setEmail(value)}
					required={true}
				/>
			</Form.Group>
			<Form.Group>
				<Button variant="primary" type="submit">
					Send
				</Button>
			</Form.Group>
		</Form>
	) : (
		<p align="center">Please check your email.</p>
	);
};

const LoginForm = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);
	const [forgotPassword, setForgotPassword] = useState(false);

	const handleSubmit = async () => {
		try {
			const { success, error, token } = await API.action.login({
				email,
				password
			});

			if (success) {
				localStorage.setItem("token", token);

				const decoded = jwt_decode(token);

				if (decoded.auth !== "none") window.location.reload();
			} else {
				setError(error);
			}
		} catch (ex) {
			console.error(ex.toString());
		}
	};

	return (
		<React.Fragment>
			<Container style={{ maxWidth: "500px" }}>
				<Form onSubmit={e => handleSubmit(e)}>
					<Form.Group>
						<Form.Label>Email</Form.Label>
						<Form.Input
							placeholder="Enter your email"
							type="email"
							onChange={({ value }) => setEmail(value)}
							required={true}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Password</Form.Label>
						<Form.Input
							placeholder="Enter your password"
							type="password"
							onChange={({ value }) => setPassword(value)}
							required={true}
						/>
					</Form.Group>
					<Form.Group>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Form.Group>
				</Form>
				<Link to="" onClick={() => setForgotPassword(true)}>
					<p
						className="text-primary"
						align="center"
						style={{ marginTop: "50px" }}
					>
						<small>Forgot password?</small>
					</p>
				</Link>
				<Status error>{error}</Status>
			</Container>

			<Modal
				show={forgotPassword}
				onClose={() => setForgotPassword(false)}
			>
				<Modal.Body>
					<ForgotPassword
						onError={setError}
						onSubmit={() => setForgotPassword(false)}
					/>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};

export default LoginForm;
