import React, { useState, useEffect, setGlobal } from "reactn";
import { Switch, Route, withRouter } from "react-router-dom";
import API from "../../api";

import Container from "../../components/Container";
import Status from "../../components/Status";
import Record from "./Record";

import { lastChanged } from "../../functions";
import Records from "../../components/Records";
import Confirm from "../../components/Confirm";
import Header from "../../components/Header";

const Main = ({
	match: {
		url,
		params: { id: contract_id }
	},
	history,
	canArchive,
	canViewTrainingComments
}) => {
	useEffect(function() {
		(function() {
			setGlobal({ title: "Contract" });
		})();
	}, []);

	const [contract, setContract] = useState(null);
	const [records, setRecords] = useState(null);
	const [error, setError] = useState(null);

	useEffect(function() {
		(async function() {
			const { success, contract, error } = await API.contracts.findOne(
				contract_id
			);

			if (success) setContract(contract);
			else setError(error);
		})();
		(async function() {
			const { success, records, error } = await API.records.find(
				contract_id
			);

			if (success) {
				let recordList = records;

				if (!canViewTrainingComments) {
					recordList = records.filter(
						({ title }) => title !== "Training Comments"
					);
				}

				setRecords(recordList);
			} else setError(error);
		})();
	}, []);

	const handleArchive = async () => {
		const { success, error } = await API.contracts.archiveOne(contract_id);

		if (success) history.push("/contracts");
		else setError(error);
	};

	return (
		<>
			{<Status error>{error}</Status>}
			{contract ? (
				<Container>
					<Header>
						<Header.Title>{contract.title}</Header.Title>
						<Header.Subtitle>
							Last changed{" "}
							{lastChanged(new Date(contract.updatedAt))} ago
						</Header.Subtitle>
					</Header>
					{records && <Records records={records} url={url} />}
					{canArchive && (
						<Confirm onConfirm={() => handleArchive()}>
							Archive contract
						</Confirm>
					)}
				</Container>
			) : (
				<Status.Switch data={records} />
			)}
		</>
	);
};

const Contract = props => (
	<Switch>
		<Route
			exact
			path={`${props.match.path}`}
			render={() => <Main {...props} />}
		/>
		<Route
			path={`${props.match.path}/record/:id`}
			render={childProps => (
				<Record
					contract_id={props.match.params.id}
					{...childProps}
					canEdit={props.canArchive}
				/>
			)}
		/>
	</Switch>
);

export default withRouter(Contract);
