import React, { useState, useEffect, setGlobal } from "reactn";
import { withRouter } from "react-router-dom";
import Form from "../../components/Form";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Status from "../../components/Status";
import API from "../../api";
import { getUserId } from "../../functions";

const CreateContract = ({ history, canSelectClient }) => {
	useEffect(function() {
		(function() {
			setGlobal({ title: "New Contract" });
		})();
	}, []);

	const [clientList, setClientList] = useState([]);
	const [title, setTitle] = useState("");
	const [client, setClient] = useState("");
	const [forms, setForms] = useState(null);
	const [error, setError] = useState(null);
	const [submitted, setSubmitted] = useState(false);

	useEffect(function() {
		(async function() {
			if (canSelectClient) {
				const { success, error, users } = await API.users.find({
					auth: "client",
					archived: false
				});

				if (success) {
					setClientList(
						users.map(({ _id, forename, surname, email }) => ({
							value: _id,
							text: `${forename} ${surname} - ${email}`
						}))
					);
				} else setError(error);
			} else {
				setClientList(null);
				setClient(getUserId());
			}
		})();
		(async function() {
			const { success, error, forms } = await API.forms.find({
				mandatory: false
			});

			if (success) {
				setForms(
					forms.map(({ _id, title }) => ({
						_id,
						title,
						checked: false
					}))
				);
			} else setError(error);
		})();
	}, []);

	const handleSubmit = async () => {
		// To prevent trigger happy users from creating multiple contracts. Tsk tsk.
		if (submitted === false) {
			setSubmitted(true);

			const body = { title, client };

			body.forms = forms
				.filter(({ checked }) => checked === true)
				.map(({ _id }) => _id);

			const { success, error } = await API.contracts.insertOne(body);

			if (success) history.push("/contracts");
			else setError(error);
		}
	};

	const toggleForm = title => {
		const index = forms.findIndex(form => form.title === title);
		forms[index].checked = !forms[index].checked;

		setForms(forms);
	};

	return (
		<Container>
			{<Status error>{error}</Status>}
			{forms ? (
				<Form onSubmit={() => handleSubmit()}>
					{/* Title */}
					<Form.Group>
						<Form.Label>Contract Title</Form.Label>
						<Form.Input
							required
							type="text"
							placeholder="Enter contract title"
							onChange={({ value }) => setTitle(value)}
						/>
					</Form.Group>

					{/* Client lookup */}
					{canSelectClient &&
						(clientList ? (
							<Form.Group>
								<Form.Label>Client</Form.Label>
								<Form.Lookup
									required
									items={clientList}
									onChange={({ value }) => setClient(value)}
								/>
							</Form.Group>
						) : (
							<Status.Switch data={clientList} />
						))}

					{/* Form checklist */}
					<h4 style={{ margin: "20px 0 5px" }}>Forms</h4>
					<p
						className="text-muted"
						style={{ margin: "0 0 10px", fontStyle: "italic" }}
					>
						Please mark the forms that the client should be required
						to complete.
					</p>

					{forms.map(({ title, checked }, index) => (
						<Form.Group key={index}>
							<Form.Label>{title}</Form.Label>
							<Form.Checkbox
								checked={checked}
								onChange={() => toggleForm(title)}
							/>
						</Form.Group>
					))}

					<Form.Group>
						<Button type="submit" variant="primary">
							Create Contract
						</Button>
					</Form.Group>
				</Form>
			) : (
				<Status.Switch data={forms} />
			)}
		</Container>
	);
};

export default withRouter(CreateContract);
