import React, {useState} from "reactn";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileSelect = props => {
    const [fileName, setFileName] = useState(null);

    const handleChange = ({target: {files}}) => {
        setFileName(files[0].name);
    }

    return (
        <div style={{position: "relative"}}>
            <input 
                {...props} 
                onChange={event => handleChange(event)} 
                variant="white"/>
            <FontAwesomeIcon icon={faFile}/>
            <div variant={props.variant || "white"} className="form-field-overlay">{fileName || "Choose file"}</div>
        </div>
    );
}

export default FileSelect;