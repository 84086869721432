import React, { useState, useEffect, setGlobal } from "reactn";
import { Route, Switch } from "react-router-dom";

import jwt_decode from "jwt-decode";

import API from "../api";
import { getAuthorizedView } from "../functions";
import Status from "../components/Status";

import LoginForm from "../components/LoginForm";

import Client from "./Client";
import Employee from "./Employee";
import Admin from "./Admin";
import View from "./View";

const NoAuthRouter = () => (
	<Switch>
		<Route exact path={`/`} render={(props) => <LoginForm {...props} />} />
	</Switch>
);

const ClientRouter = () => (
	<Switch>
		<Route
			exact
			path={`/`}
			render={(props) => <Client.Dashboard {...props} />}
		/>
		<Route
			exact
			path={`/contract`}
			render={(props) => <View.CreateContract {...props} />}
		/>
		<Route
			path={`/contract/:id`}
			render={(props) => <View.Contract {...props} canArchive />}
		/>
		<Route
			path={`/contracts`}
			render={(props) => <View.Contracts {...props} canCreate />}
		/>
		<Route
			path={`/data-sheets`}
			render={(props) => <View.DataSheets {...props} />}
		/>
		<Route
			path={`/user/me`}
			render={(props) => <View.Profile {...props} />}
		/>
		<Route
			path={`/resetpassword`}
			render={(props) => <View.PasswordReset {...props} />}
		/>
		<Route
			path={`/logout`}
			render={(props) => <View.LogOut {...props} />}
		/>
	</Switch>
);

const EmployeeRouter = () => (
	<Switch>
		<Route
			exact
			path={`/`}
			render={(props) => <Employee.Dashboard {...props} />}
		/>
		<Route
			path={`/contract/:id`}
			render={(props) => <View.Contract {...props} />}
		/>
		<Route
			path={`/contracts`}
			render={(props) => <View.Contracts {...props} />}
		/>
		<Route
			path={`/data-sheets`}
			render={(props) => <View.DataSheets {...props} />}
		/>
		<Route
			path={`/user/me`}
			render={(props) => <View.Profile {...props} />}
		/>
		<Route
			path={`/user/:id`}
			render={(props) => <View.User {...props} />}
		/>
		<Route path={`/users`} render={(props) => <View.Users {...props} />} />
		<Route
			path={`/resetpassword`}
			render={(props) => <View.PasswordReset {...props} />}
		/>
		<Route
			path={`/logout`}
			render={(props) => <View.LogOut {...props} />}
		/>
	</Switch>
);

const AdminRouter = () => (
	<Switch>
		<Route
			exact
			path={`/`}
			render={(props) => <Admin.Dashboard {...props} />}
		/>
		<Route
			exact
			path={`/contract`}
			render={(props) => (
				<View.CreateContract {...props} canSelectClient />
			)}
		/>
		<Route
			path={`/contract/:id`}
			render={(props) => (
				<View.Contract {...props} canArchive canViewTrainingComments />
			)}
		/>
		<Route
			path={`/contracts`}
			render={(props) => (
				<View.Contracts {...props} canCreate canShowArchived />
			)}
		/>
		<Route
			path={`/data-sheets`}
			render={(props) => <View.DataSheets {...props} />}
		/>
		<Route
			exact
			path={`/user`}
			render={(props) => <Admin.CreateUser {...props} />}
		/>
		<Route
			path={`/user/me`}
			render={(props) => <View.Profile {...props} />}
		/>
		<Route
			path={`/user/:id`}
			render={(props) => <View.User {...props} canEdit canDelete />}
		/>
		<Route
			path={`/users`}
			render={(props) => <View.Users {...props} canCreate />}
		/>
		<Route
			path={`/settings`}
			render={(props) => <Admin.Settings {...props} />}
		/>
		<Route
			path={`/resetpassword`}
			render={(props) => <View.PasswordReset {...props} />}
		/>
		<Route
			path={`/logout`}
			render={(props) => <View.LogOut {...props} />}
		/>
	</Switch>
);

const Index = (props) => {
	const [view, setView] = useState(<Status loading />);
	const [error, setError] = useState(null);

	const applyAuth = async () => {
		let user = { auth: "none" };

		if ((await API.action.validate()).success) {
			try {
				user = jwt_decode(localStorage.getItem("token"));
			} catch (ex) {
				setError(ex.toString());
				console.error(ex.toString());
			}
		}

		const view = getAuthorizedView(user.auth, {
			none: <NoAuthRouter {...props} />,
			client: <ClientRouter {...props} />,
			employee: <EmployeeRouter {...props} />,
			admin: <AdminRouter {...props} />,
		});

		setGlobal({ auth: user.auth });
		setView(view);
	};

	useEffect(function () {
		(async function () {
			applyAuth();
		})();
	}, []);

	return view || <Status error>{error}</Status>;
};

export default Index;
