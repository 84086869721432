import React, {useState} from "react";

import Form from "./Form";
import Status from "./Status";

const CodeEditor = ({onChange, defaultValue}) => {
    const [value, setValue] = useState(defaultValue);
    const [error, setError] = useState(null);

    const handleTab = function(event) {
        const _this = event.target;

        if (event.which === 9) {
            event.preventDefault();
            var start = _this.selectionStart;
            var end = _this.selectionEnd;
        
            // set textarea value to: text before caret + tab + text after caret
            _this.value = _this.value.substring(0, start)
                        + "\t"
                        + _this.value.substring(end);
        
            // put caret at right position again
            _this.selectionStart = _this.selectionEnd = start + 1;
        }
    }

    const handleChange = value => {
        setValue(value);

        try {
            // Test for invalid syntax
            new Function(`return (${value})`)();

            onChange(value);
            setError(null);
        } catch(ex) {
            onChange(value);
            setError(ex.toString());
        }
    }

    return (<>
        <Status error>{error}</Status>
        <Form.TextArea 
            required
            onKeyDown={e => (e.which === 9) && handleTab(e)}
            value={value} 
            style={{
                height: "400px", 
                fontSize: "14px", 
                fontFamily: `"Menlo", "Inconsolata", monospace`
            }}
            onChange={({value}) => handleChange(value)}/>
    </>);
}

export default CodeEditor;