import React from "react";
import {Link} from "react-router-dom";
import Section from "../components/Section";
import Panel from "../components/Panel";

const Records = ({records, url}) => (<>
    <Section title="Incomplete">
        {records.filter(record => !record.completed).map(({_id, title}, index) => (
            <Link key={`record-${index}`} to={`${url}/record/${_id}`}>
                <Panel>
                    <Panel.Title>{title}</Panel.Title>
                </Panel>
            </Link>
        ))}
    </Section>
    <Section title="Complete">
        {records.filter(record => record.completed).map(({_id, title}, index) => (
            <Link key={`record-${index}`} to={`${url}/record/${_id}`}>
                <Panel>
                    <Panel.Title>{title}</Panel.Title>
                </Panel>
            </Link>
        ))}
    </Section>
</>);

export default Records;