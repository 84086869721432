import React, {useState, useGlobal, useRef} from "reactn";
import {withRouter} from 'react-router-dom';
import {Link} from "react-router-dom";
import "../scss/nav.scss";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faChevronLeft} from '@fortawesome/free-solid-svg-icons';

const NavItem = ({to, children, active}) => 
    (<Link to={to}>
        <li className={active ? "active" : ""}>
            <p>{children}</p>
        </li>
    </Link>);

const NavItemContainer = ({active, children}) => {
    const cont = useRef(null);

    return <ul 
        className="nav-item-container" 
        style={{
            height: active ? `${(40 * React.Children.count(children))}px` : 0
        }} 
        ref={cont}>
            {children}
        </ul>;
}

const NavBar = ({title, history}) => {
    const [active, setActive] = useState(false);
    const [valid] = useGlobal("valid");
    const [isDirty] = useGlobal("isDirty");
    const [, setPendingDest] = useGlobal("pendingDest");

    const {location: {pathname}} = window;

    const exit = () => {
        const pages = [
            "record",
            "records",
            "form",
            "forms",
            "user",
            "users",
            "contract",
            "contracts",
            "sheet",
            "sheets",
            "settings",
            "info",
        ];

        const split = pathname.split("/");
        const last = split.slice(-1);

        if(pathname !== "/") {
            const dest = split.slice(
                0, 
                0 - Number(!pages.includes(last)) - 1
            ).join("/");

            if(!isDirty) {            
                history.push(dest);
            } else {
                setPendingDest(dest);
                
            }
        }
    }

    return (
        <nav variant="dark">
            {valid && (<>
                <section className="nav-buttons">
                    {(pathname !== "/") && <p style={{cursor: "pointer"}} className="nav-back" onClick={() => exit()}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </p>}
                    <p style={{cursor: "pointer"}} className="nav-burger" onClick={() => setActive(!active)}>
                        <FontAwesomeIcon icon={faBars}/>
                    </p>
                </section>
                <NavItemContainer active={active}>
                    <NavItem to="/">Dashboard</NavItem>
                    <NavItem to={`/user/me`}>My Profile</NavItem>
                    <NavItem to={`/logout`}>Log out</NavItem>
                    <hr/>
                    <a href="https://www.resinbonded.co.uk">
                        Main Site
                    </a>
                </NavItemContainer>
            </>)}
            <h3>{title}</h3>
        </nav>
    );

}

export default withRouter(NavBar);