const getImageDataURL = (file) =>
	new Promise((res, rej) => {
		var reader = new FileReader();

		reader.addEventListener("load", () => {
			res(reader.result);
		});
		reader.addEventListener("error", (err) => rej(err));

		reader.readAsDataURL(file);
	});

const processImage = async (dataURL) => {
	// Using canvas fixes EXIF issues, so is by far the best option.

	const image = await new Promise((res, rej) => {
		const blank = new Image();
		blank.src = dataURL;

		blank.onload = function () {
			res(this);
		};

		setTimeout(
			rej.bind(null, "Image took too long to compress. Please try again"),
			10000
		);
	});

	let { width, height } = image;

	const canvas = document.createElement("canvas");
	const maxWidthOrHeight = 500;
	const ar = width / height;

	if (width >= height) {
		width = maxWidthOrHeight;
		height = width * ar;
	} else {
		height = maxWidthOrHeight;
		width = height * ar;
	}

	canvas.width = width;
	canvas.height = height;

	canvas.getContext("2d").drawImage(image, 0, 0, width, height);

	return canvas.toDataURL("image/png", 0.8);
};

export const applyImageCorrections = async (image) => {
	// Convert file to base 64
	console.log("Converting image to base 64");
	let dataURL = await getImageDataURL(image);

	console.log("Processing image");
	dataURL = await processImage(dataURL);

	return dataURL;
};
