import React, {useEffect, setGlobal} from "reactn";

import Container from "../../components/Container";
import Columns from "../../components/Columns";
import Panel from "../../components/Panel";
import CreateForm from "./CreateForm";
import FormEditor from "./FormEditor";
import Forms from "./Forms";
import {Link, Switch, Route} from "react-router-dom";

const Main = ({match: {url}}) => {
    useEffect(function() {
        (function() {
            setGlobal({title: "Advanced Settings"})
        })();
    }, []);

    return (<Container>
        <Columns>
            <Panel>
                <Link to={`${url}/forms`}>
                    <Panel.Title>
                        Manage Forms
                    </Panel.Title>
                    <Panel.Body>
                        <p>Edit form JSON. For developers.</p>
                        <Panel.Image src={require("../../assets/vectors/code.svg")}/>
                    </Panel.Body>
                </Link>
            </Panel>
        </Columns>
    </Container>);
}

const Settings = ({match: {url}}) => (
    <Switch>
        <Route exact path={url} render={props => <Main {...props}/>}/>
        <Route exact path={`${url}/form`} render={props => <CreateForm {...props}/>}/>
        <Route path={`${url}/form/:id`} render={props => <FormEditor {...props}/>}/>
        <Route path={`${url}/forms`} render={props => <Forms {...props}/>}/>
    </Switch>)

export default Settings;