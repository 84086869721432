import "../scss/modal.scss";

import React from "react";

const Modal = ({ show, children, onClose }) =>
	show && (
		<>
			<div className="overlay" onClick={(e) => onClose(e)} />
			<div className="modal" style={{ top: `${window.scrollY}px` }}>
				{children}
			</div>
		</>
	);

Modal.Body = ({ children }) => (
	<div style={{ clear: "both", marginBottom: "15px" }}>{children}</div>
);
Modal.ButtonGroup = ({ children }) => (
	<section style={{ clear: "both", float: "right" }}>{children}</section>
);

export default Modal;
