import React, { useState, useEffect, setGlobal } from "reactn";
import { withRouter } from "react-router-dom";
import Container from "../../components/Container";
import Status from "../../components/Status";
import Form from "../../components/Form";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import API from "../../api";

const PasswordReset = ({ location: { search }, history }) => {
	useEffect(function() {
		(function() {
			setGlobal({ title: "Reset Password" });
		})();
	}, []);

	const token = new URLSearchParams(search).get("token");

	const [valid, setValid] = useState(null);
	const [password, setPassword] = useState("");
	const [confirm, setConfirm] = useState("");
	const [modal, setModal] = useState(false);
	const [error, setError] = useState(null);

	useEffect(
		function() {
			(async function() {
				const { success } = await API.action.validateToken({ token });

				if (success) {
					setValid(true);
				} else {
					console.warn("Token not found");
					window.setTimeout(() => {
						if (!success) history.push("/");
					}, 2000);
				}
			})();
		},
		[history, token]
	);

	const handleSubmit = async () => {
		if (password === confirm) {
			const { success, error } = await API.action.resetPassword({
				token,
				password
			});

			if (success) setModal(true);
			else setError(error);
		} else setError("Passwords do not match");
	};

	return (
		<Container style={{ maxWidth: "500px" }}>
			<Status error>{error}</Status>
			{valid ? (
				<Form onSubmit={e => handleSubmit(e)}>
					<Form.Group>
						<Form.Label>Password</Form.Label>
						<Form.Input
							placeholder="Enter new password"
							type="password"
							onChange={({ value }) => setPassword(value)}
							required={true}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Confirm Password</Form.Label>
						<Form.Input
							placeholder="Confirm new password"
							type="password"
							onChange={({ value }) => setConfirm(value)}
							required={true}
						/>
					</Form.Group>
					<Form.Group>
						<Button variant="primary" type="submit">
							Reset Password
						</Button>
					</Form.Group>
				</Form>
			) : (
				<Status loading />
			)}
			<Modal show={modal} onClose={() => setModal(false)}>
				<Modal.Body>Password successfully reset.</Modal.Body>
				<Modal.ButtonGroup>
					<Button variant="secondary" onClick={() => setModal(false)}>
						Close
					</Button>
				</Modal.ButtonGroup>
			</Modal>
		</Container>
	);
};

export default withRouter(PasswordReset);
