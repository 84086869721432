import React from "react";

const TextArea = props => {
    const handleChange = ({target: {value}}) => {
        const state = {...props};
        state.value = value;
        
        props.onChange(state);
    }

    const inherit = {...props};
    delete inherit.onChange;

    return <textarea onChange={event => handleChange(event)} {...inherit}/>;
}

export default TextArea;