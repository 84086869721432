import React, { setGlobal, useEffect, useState } from "reactn";

import API from "../../api";
import Columns from "../../components/Columns";
import Container from "../../components/Container";
import { Link } from "react-router-dom";
import Panel from "../../components/Panel";
import Section from "../../components/Section";
import Status from "../../components/Status";

const Contracts = ({ canCreate, canShowArchived }) => {
	useEffect(function () {
		(function () {
			setGlobal({ title: "Contracts" });
		})();
	}, []);

	const [contracts, setContracts] = useState(null);
	const [error, setError] = useState(null);
	const [showArchived, setShowArchived] = useState(false);

	useEffect(
		function () {
			(async function () {
				const query = showArchived ? {} : { archived: false };

				const { success, error, contracts } = await API.contracts.find(
					query
				);

				if (success) setContracts(contracts);
				else setError(error);
			})();
		},
		[showArchived]
	);

	return (
		<Container>
			{canCreate && (
				<Link to={`/contract`}>
					<Panel>
						<Panel.Title>Initiate new contract</Panel.Title>
					</Panel>
				</Link>
			)}
			<Section title="Contracts">
				<Status error>{error}</Status>
				{contracts ? (
					<Columns>
						{contracts.map(
							(
								{ _id, title, records, completedRecordsCount },
								index
							) => {
								const incomplete =
									records.length - completedRecordsCount;

								return (
									<Panel key={index}>
										<Link to={`/contract/${_id}`}>
											{incomplete > 0 && (
												<Panel.Label variant="danger">
													{incomplete}
												</Panel.Label>
											)}
											<Panel.Title>{title}</Panel.Title>
											<Panel.Body>
												<p
													style={{
														margin: 0,
														fontWeight: "normal",
													}}
												>{`${records.length} records`}</p>
											</Panel.Body>
										</Link>
									</Panel>
								);
							}
						)}
					</Columns>
				) : (
					<Status.Switch data={contracts} />
				)}
				{canShowArchived && (
					<a onClick={() => setShowArchived(!showArchived)}>
						<p
							className="text-primary"
							style={{ textAlign: "center" }}
						>
							{showArchived ? "Hide" : "Show"} archived contracts
						</p>
					</a>
				)}
			</Section>
		</Container>
	);
};

export default Contracts;
