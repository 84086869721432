import React, { useState, useRef, useEffect } from "react";
import Container from "../../components/Container";
import Table from "../../components/Table";
import Status from "../../components/Status";
import { switchTableData } from "../../functions";
import API from "../../api";
import "../../scss/print.scss";
import { Helmet } from "react-helmet";
// Get the PDF.js library and have a look at it, perhaps use it to fix the printing issue on mobile devices.

const PrintRecord = ({
	match: {
		url,
		params: { id: record_id }
	},
	contract_id
}) => {
	const [record, setRecord] = useState(null);
	const [error, setError] = useState(null);
	const [meta, setMeta] = useState(
		<meta
			name="viewport"
			content="width=794, height=1123, initial-scale=1"
		/>
	);

	const logo = useRef(null);

	const leave = () => {
		window.removeEventListener("afterprint", leave, false);

		setMeta(
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1"
			/>
		);

		// `url` is passed by props, so is actually one level up
		// use window location to refresh the meta tag.
		window.location.href = url;
	};

	useEffect(function() {
		window.addEventListener("afterprint", leave, false);
		(async function() {
			const { success, error, record } = await API.records.findOne(
				contract_id,
				record_id
			);

			if (success) {
				setRecord(record);
			} else setError(error);
		})();
	}, []);

	return record ? (
		<>
			<Helmet>{meta}</Helmet>
			<Container className="print-container">
				<Status error>{error}</Status>
				<header className="page-header">
					<img
						ref={logo}
						onLoad={() => window.print()}
						alt="Logo"
						className="logo"
						src={require("../../assets/images/main-logo.jpg")}
					/>
					<h1 className="title">{record.title}</h1>
				</header>
				<footer className="page-footer">
					<p align="center" className="text-muted small">
						<small>
							Unit F1, Birdineye Farm, Framfield Road, Uckfield,
							TN22 5HA
							<br />
							Tel: 01825 891329
							<br />
							Email: info@resinbonded.co.uk
						</small>
					</p>
				</footer>
				<div className="print-body">
					<Table className="print">
						<thead>
							<tr>
								{/* print header space hack */}
								<td>
									<div className="page-header-space" />
								</td>
							</tr>
						</thead>
						<tbody>
							{record.fields.length ? (
								record.fields
									.sort((a, b) => a.uid - b.uid)
									.map((field, index) => {
										return (
											<tr key={index}>
												<th>{field.label}</th>
												<td>
													{switchTableData(field)}
												</td>
											</tr>
										);
									})
							) : (
								<tr>
									<td>
										<Status empty />
									</td>
								</tr>
							)}
						</tbody>
						<tfoot>
							<tr>
								{/* print footer space hack */}
								<td>
									<div className="page-footer-space" />
								</td>
							</tr>
						</tfoot>
					</Table>
				</div>
			</Container>
		</>
	) : (
		<Status loading />
	);
};

export default PrintRecord;
