import React, { useState } from "reactn";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Status from "../Status";

import { applyImageCorrections } from "./functions";

const ImageSelect = (props) => {
	const [fileName, setFileName] = useState(null);
	const [preview, setPreview] = useState(null);
	const [error, setError] = useState(null);

	const handleChange = ({ target: { files } }) => {
		(async function () {
			try {
				console.log("Applying image corrections");
				const b64 = await applyImageCorrections(files[0]);

				setFileName(files[0].name);
				setPreview(b64);

				const state = { ...props };
				state.value = b64;

				props.onChange(state);
			} catch(ex) {
				console.error(ex);
				setError(ex.toString());
			}
		})();
	};

	const inherit = { ...props };
	delete inherit.type;
	delete inherit.onChange;

	return (
		<div>
			<img alt="" src={preview || ""} className="form-image-preview" />
			<div style={{ position: "relative" }}>
				<input
					{...inherit}
					type="file"
					onChange={handleChange}
					variant="white"
					accept="image/*"
				/>
				<FontAwesomeIcon icon={faImage} />
				<div
					variant={props.variant || "white"}
					className="form-field-overlay"
				>
					{fileName || "Choose image"}
				</div>
			</div>
			<Status error>{error}</Status>
		</div>
	);
};

export default ImageSelect;
