import React, {useState, useEffect, setGlobal} from "reactn";
import Status from "../../components/Status";
import API from "../../api";

import Container from "../../components/Container";
import Panel from "../../components/Panel";
import {Link} from "react-router-dom";
import Columns from "../../components/Columns";
import Section from "../../components/Section";

const Forms = ({match: {url}}) => {
    useEffect(function() {
        (function() {
            setGlobal({title: "Forms"})
        })();
    }, []);

    const [forms, setForms] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function() {
        (async function() {
            const {success, error, forms} = await API.forms.find({});

            if(success) setForms(forms);
            else setError(error);
        })();
    }, []);

    return (<Container>
        <Status error>{error}</Status>

        <Link to={`/settings/form`}>
            <Panel>
                <Panel.Title>
                    Create new form
                </Panel.Title>
            </Panel>
        </Link>
        
        <Section title="Available Forms">
            <Columns>
                {
                    forms ? 
                    forms.map(({_id, title}, index) => (
                        <Panel key={index}>
                            <Link to={`${url.split("/").slice(0, -1).join("/")}/form/${_id}`}>
                                <Panel.Title>
                                    {title}
                                </Panel.Title>
                            </Link>
                        </Panel>
                    )) : <Status empty/>
                }
            </Columns>
        </Section>
    </Container>);
}

export default Forms;