import React, { useEffect, setGlobal } from "reactn";

import { Link } from "react-router-dom";

import Container from "../../components/Container";
import Panel from "../../components/Panel";
import Columns from "../../components/Columns";

const Dashboard = () => {
	useEffect(function () {
		(function () {
			setGlobal({ title: "Dashboard" });
		})();
	}, []);

	return (
		<Container>
			<Columns>
				<Panel variant="white">
					<Link to="/contracts">
						<Panel.Title>Manage Contracts</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/contract.svg")}
							/>
						</Panel.Body>
					</Link>
				</Panel>

				{/* --- Might add at a later point... --- */}
				{/* <Panel variant="white">
                <Link to="/contracts?completed=true">
                    <Panel.Title>
                        Completed Jobs
                    </Panel.Title>
                    <Panel.Body>
                        <Panel.Image src={require("../../assets/vectors/completed-jobs.svg")}/>
                    </Panel.Body>
                </Link>
            </Panel> */}

				{/* --- Wasn't being used --- */}
				{/* <Panel variant="white">
					<Panel.Title>General Practices</Panel.Title>
					<Panel.Body>
						<Panel.Image
							src={require("../../assets/vectors/general-practices.svg")}
						/>
					</Panel.Body>
				</Panel> */}

				<Panel variant="white">
					<Link to="/data-sheets">
						<Panel.Title>Data Sheets</Panel.Title>
						<Panel.Body>
							<Panel.Image
								src={require("../../assets/vectors/data-sheets.svg")}
							/>
						</Panel.Body>
					</Link>
				</Panel>
			</Columns>
		</Container>
	);
};

export default Dashboard;
