import React, {useState, useEffect, setGlobal} from "reactn";
import API from "../../api";

import {Link} from "react-router-dom";

import Status from "../../components/Status";
import Container from "../../components/Container";
import Panel from "../../components/Panel";
import Columns from "../../components/Columns";
import Section from "../../components/Section";

const Users = ({canCreate}) => {
    useEffect(function() {
        (function() {
            setGlobal({title: "Users"});
        })();
    }, []);

    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(function() {
        (async function() {
            const {success, error, users} = await API.users.find({archived: false});

            if(success) setUsers(users);
            else setError(error);
        })();
    }, []);

    return (<Container>
        <Status error>{error}</Status>
        {canCreate && <Link to={`/user`}>
            <Panel>
                <Panel.Title>
                    Create new user
                </Panel.Title>
            </Panel>
        </Link>}
        {users ? (<Section title="Users">
            <Columns>
                {users.map(({_id, surname, forename, auth}, index) => (
                    <Panel key={index}>
                        <Link to={`user/${_id}`}>
                            <Panel.Title>
                                {surname}, {forename} 
                            </Panel.Title>
                            <Panel.Body>
                                {auth}
                            </Panel.Body>
                        </Link>
                    </Panel>
                ))}
            </Columns>
        </Section>) : <Status.Switch data={users}/>}
    </Container>);
};

export default Users;