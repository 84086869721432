import Dashboard from "./admin/Dashboard";
import Settings from "./admin/Settings";
import CreateForm from "./admin/CreateForm";
import CreateUser from "./admin/CreateUser";
import FormEditor from "./admin/FormEditor";
import Forms from "./admin/Forms";

const Admin = {
    Dashboard,
    Settings,
    FormEditor,
    Forms,
    CreateForm,
    CreateUser
};

export default Admin;